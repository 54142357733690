import { createReducer, on } from '@ngrx/store';
import {
  applyJobFailure,
  applyJobSuccess,
  createJobFailure,
  createJobSuccess,
  getAllDraftsSuccess,
  getJobDetailsError,
  getJobDetailsSuccess,
  getJobsError,
  getJobsSuccess,
  setEditJobId,
  setFavoriteUpdated,
  setLoadDraftId,
  setSelectedJobId,
  sortJobs,
} from './jobs.actions';
import { JobsState, initialState } from './jobs.state';

const _jobsReducer = createReducer(
  initialState,
  on(createJobSuccess, (state: JobsState, action): JobsState => {
    return {
      ...state,
      createJobSuccess: action.createJobSuccess,
      createJobSuccessId: action.jobId,
    };
  }),
  on(createJobFailure, (state: JobsState, action): JobsState => {
    return {
      ...state,
      createJobFailure: action.createJobError,
    };
  }),
  on(setSelectedJobId, (state: JobsState, action): JobsState => {
    return {
      ...state,
      selectedJobId: action.id,
    };
  }),
  on(getJobDetailsSuccess, (state: JobsState, action): JobsState => {
    return {
      ...state,
      jobDetails: action.job,
    };
  }),
  on(getJobDetailsError, (state: JobsState, action): JobsState => {
    return {
      ...state,
      jobDetailsError: action.error,
    };
  }),
  on(getJobsSuccess, (state: JobsState, action): JobsState => {
    return {
      ...state,
      jobs: action.jobs,
      totalRecords: action.totalRecords,
    };
  }),
  on(getJobsError, (state: JobsState, action): JobsState => {
    return {
      ...state,
      jobs: [],
      jobsError: action.error,
    };
  }),
  on(applyJobSuccess, (state: JobsState, action): JobsState => {
    return {
      ...state,
      applyJobSuccess: action.job,
    };
  }),
  on(applyJobFailure, (state: JobsState, action): JobsState => {
    return {
      ...state,
      applyJobFailure: action.error,
    };
  }),
  on(setEditJobId, (state: JobsState, { jobId }): JobsState => {
    return {
      ...state,
      editJobId: jobId,
    };
  }),
  on(setFavoriteUpdated, (state: JobsState, action): JobsState => {
    return {
      ...state,
      favoriteUpdated: action.value,
    };
  }),
  on(sortJobs, (state: JobsState, action): JobsState => {
    return {
      ...state,
      sortValue: action.sortValue,
    };
  }),

  on(getAllDraftsSuccess, (state: JobsState, action): JobsState => {
    return {
      ...state,
      drafts: action.drafts,
    };
  }),
  on(setLoadDraftId, (state: JobsState, action): JobsState => {
    return {
      ...state,
      loadDraftId: action.draftId,
    };
  })
);

export function jobsReducer(state: any, action: any) {
  return _jobsReducer(state, action);
}
