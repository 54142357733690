import { createReducer, on } from '@ngrx/store';
import {
  getChatsError,
  getChatsSuccess,
  getMessagesError,
  getMessagesSuccess,
  sendMessageError,
  sendMessageSuccess,
  setRefreshAllMessages,
  setSelectedChat,
} from './messages.actions';
import { MessagesState, initialMessagesState } from './messages.state';

export const _messagesReducer = createReducer(
  initialMessagesState,
  on(
    setSelectedChat,
    (state: MessagesState, { chat }): MessagesState => ({
      ...state,
      selectedChat: chat,
    })
  ),
  on(
    getChatsSuccess,
    (state: MessagesState, { chats }): MessagesState => ({
      ...state,
      chats: chats,
    })
  ),
  on(
    getChatsError,
    (state: MessagesState, { message }): MessagesState => ({
      ...state,
      chatsError: message,
    })
  ),
  on(
    getMessagesSuccess,
    (state: MessagesState, { messages }): MessagesState => ({
      ...state,
      messages: messages,
    })
  ),
  on(
    getMessagesError,
    (state: MessagesState, { message }): MessagesState => ({
      ...state,
      messagesError: message,
    })
  ),

  on(
    sendMessageError,
    (state: MessagesState, { isSendMessageFailure }): MessagesState => ({
      ...state,
      sendMessageFailure: isSendMessageFailure,
      sendMessageSuccess: false,
    })
  ),
  on(
    sendMessageSuccess,
    (state: MessagesState, { isSendMessageSuccess }): MessagesState => ({
      ...state,
      sendMessageFailure: false,
      sendMessageSuccess: isSendMessageSuccess,
    })
  ),
  on(
    setRefreshAllMessages,
    (state: MessagesState, { refreshAll }): MessagesState => ({
      ...state,
      refreshAll: refreshAll,
    })
  )
);

export function messagesReducer(state: any, action: any) {
  return _messagesReducer(state, action);
}
