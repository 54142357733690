import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecruiterService {
  constructor(private http: HttpClient) {}

  getRecruiterDetails(
    accountId: string,
    registerType: string
  ): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getRecruiterDetails,
      { accountId, registerType }
    );
  }

  getIndFilmMakerProfileDetails(
    accountId: string,
    registerType: string
  ): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getIndependentFilmMakerProfile,
      { accountId, registerType }
    );
  }

  getJobsCreated(request: { filter: {} }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getJobs,
      request
    );
  }

  updateRecruiterProfileDetails(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.updateRecruiterProfile,
      payload
    );
  }

  updateIndFilmMakerProfileDetails(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl +
        environment.api.updateIndependentFilmMakerProfile,
      payload
    );
  }

  likeUserProfile(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.likeUserProfile,
      payload
    );
  }

  bookMarkUserProfile(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.bookMarkUserProfile,
      payload
    );
  }

  saveNotesForUserProfile(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.saveNotesForUserProfile,
      payload
    );
  }

  getProjects(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getProjects,
      payload
    );
  }

  createProject(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.createOrUpdateProject,
      payload
    );
  }

  updateShortlistName(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.createOrUpdateShortlist,
      payload
    );
  }

  updateShortlistProfiles(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.updateShortlistProfiles,
      payload
    );
  }

  getSavedNotes(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getnotesprofiles,
      payload
    );
  }
}
