import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, filter } from 'rxjs';
import { ShortlistBottomPopupComponent } from './shared/components/shortlist-bottom-popup/shortlist-bottom-popup.component';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { SessionTimeoutService } from './shared/services/session-timeout.service';
import { appLogout } from './store';
import { getTokens } from './store/account/account.actions';
import { selectLoggedInUser } from './store/account/account.selectors';
import {
  openSnackBar,
  toggleBottomSheet,
  updateUrlPath,
} from './store/core/core.actions';
import {
  selectBottomSheet,
  selectSnackBar,
  selectSpinner,
} from './store/core/core.selector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  toggleSpinner$ = this.store.select(selectSpinner);
  snackBarObs$ = this.store.select(selectSnackBar);
  navUrl: string = '/';
  loaderMessage: string = '';
  subscriptions: Subscription[] = [];
  private readonly REFRESH_INTERVAL = 10 * 60 * 1000; // 13 minutes
  private refreshTokenIntervalId: any;

  constructor(
    private router: Router,
    private store: Store,
    private spinner: NgxSpinnerService,
    private changeDetection: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private sessionTimeoutService: SessionTimeoutService,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.subscriptions[0] = this.toggleSpinner$.subscribe((toggleSpinner) => {
      if (toggleSpinner.show) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
      this.loaderMessage = toggleSpinner.message;
      this.changeDetection.detectChanges();
    });
    this.subscriptions[1] = this.snackBarObs$.subscribe((snackBar) => {
      if (snackBar.show) {
        this.showSnackBar();
        this.store.dispatch(
          openSnackBar({ snackBar: { message: '', show: false } })
        );
      }
    });
    this.subscriptions[2] = this.router.events
      .pipe(
        filter(
          (e): e is NavigationEnd =>
            e instanceof NavigationEnd || e instanceof Scroll
        )
      )
      .subscribe((event: NavigationEnd | Scroll) => {
        if (event instanceof NavigationEnd) {
          this.navUrl = event.urlAfterRedirects;
        } else if (event instanceof Scroll) {
          this.navUrl = event.routerEvent.url;
        }
        if (this.navUrl === '/') {
          console.log('logout from app component');
          this.store.dispatch(appLogout());
        }
        this.store.dispatch(updateUrlPath({ urlPath: this.navUrl }));
      });

    this.subscriptions[3] = this.store
      .select(selectLoggedInUser)
      .subscribe((user) => {
        if (user?.accountId) {
          console.log('start monitoring session', this.refreshTokenIntervalId);
          if (!this.refreshTokenIntervalId) {
            this.startRefreshTokenMonitoring();
          }
        } else {
          console.log('clearing interval');
          this.clearRefreshTokenMonitoring();
        }
      });

    this.subscriptions[4] = this.store
      .select(selectBottomSheet)
      .subscribe((bottomSheet) => {
        if (bottomSheet.show) {
          this.openBottomSheet(bottomSheet.data);
        } else {
          this._bottomSheet.dismiss();
        }
      });
  }

  showSnackBar() {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 5000, // Set the duration in milliseconds
    });
  }

  ngOnDestroy(): void {
    this.clearRefreshTokenMonitoring();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  startRefreshTokenMonitoring() {
    this.refreshTokenIntervalId = setInterval(() => {
      this.callRefreshToken();
    }, this.REFRESH_INTERVAL);
    console.log('start refresh token monitoring' + this.refreshTokenIntervalId);
  }

  private clearRefreshTokenMonitoring() {
    console.log('clearing refresh token interval', this.refreshTokenIntervalId);
    if (this.refreshTokenIntervalId) {
      clearInterval(this.refreshTokenIntervalId);
      this.refreshTokenIntervalId = null;
    }
  }

  callRefreshToken() {
    this.store.dispatch(getTokens());
  }

  openBottomSheet(data: { accountId: string; jobId: string }): void {
    this._bottomSheet
      .open(ShortlistBottomPopupComponent, {
        data: { accountId: data.accountId, jobId: data.jobId },
      })
      .afterDismissed()
      .subscribe(() => {
        console.log('Bottom sheet has been dismissed.');
        this.store.dispatch(
          toggleBottomSheet({
            show: false,
            data: {
              accountId: '',
              jobId: '',
            },
          })
        );
      });
  }
}
