import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './shared/components/about-us/about-us.component';
import { BehavioralComplianceComponent } from './shared/components/behavioral-compliance/behavioral-compliance.component';
import { FeedbackComponent } from './shared/components/feedback/feedback.component';
import { LandingPageComponent } from './shared/components/landing-page/landing-page.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { TermsOfUserComponent } from './shared/components/terms-of-use/terms-of-use.component';
import { AuthGuard } from './shared/guards/auth-guard.guard';
import { LoginResolveGuard } from './shared/guards/login-resolve.guard';
import { RecruiterGuard } from './shared/guards/recruiter-guard.guard';
import { UserGuard } from './shared/guards/user-guard.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    resolve: [LoginResolveGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard, UserGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard, UserGuard],
  },
  {
    path: 'recruiter',
    loadChildren: () =>
      import('./recruiter/recruiter.module').then((m) => m.RecruiterModule),
    canActivate: [AuthGuard, RecruiterGuard],
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./messages/messages.module').then((m) => m.MessagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'showcases',
    loadChildren: () =>
      import('./showCase/showCase.module').then((m) => m.ShowCaseModule),
  },
  {
    path: 'endorsements',
    loadChildren: () =>
      import('./endorsements/endorsements.module').then(
        (m) => m.EndorsementsModule
      ),
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'aboutus',
    component: AboutUsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUserComponent,
  },
  {
    path: 'behavioral-compliance',
    component: BehavioralComplianceComponent,
  },
  { path: '', component: LandingPageComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
