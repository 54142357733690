import { createReducer, on } from '@ngrx/store';
import {
  changePasswordError,
  changePasswordSuccess,
  getTokensFailed,
  setAuthToken,
  setLoggedInUser,
  setRefreshToken,
  setUserVerified,
} from './account.actions';
import { AccountState, initialAccountState } from './account.state';

export const _accountReducer = createReducer(
  initialAccountState,
  on(
    setLoggedInUser,
    (
      state: AccountState,
      { emailId, accountId, registerType, accountVerifiedBySeeneema }
    ): AccountState => {
      return {
        ...state,
        isLoggedIn: true,
        emailId: emailId,
        accountId: accountId,
        registerType: registerType,
        accountVerifiedBySeeneema: accountVerifiedBySeeneema,
      };
    }
  ),
  on(
    changePasswordSuccess,
    (state: AccountState, { status, message }): AccountState => {
      return {
        ...state,
        isChangePasswordError: false,
        changePasswordError: '',
        isChangePasswordSuccess: status,
        changePasswordSuccess: message,
      };
    }
  ),
  on(
    changePasswordError,
    (state: AccountState, { status, message }): AccountState => {
      return {
        ...state,
        isChangePasswordError: true,
        changePasswordError: message,
        isChangePasswordSuccess: false,
        changePasswordSuccess: '',
      };
    }
  ),
  on(
    setUserVerified,
    (state: AccountState, { status, emailId }): AccountState => {
      return {
        ...state,
        isAccountVerified: status,
        emailToVerify: emailId,
      };
    }
  ),
  on(setAuthToken, (state: AccountState, { token }): AccountState => {
    return {
      ...state,
      token: token,
    };
  }),
  on(setRefreshToken, (state: AccountState, { token }): AccountState => {
    return {
      ...state,
      refreshToken: token,
    };
  }),
  on(getTokensFailed, (state: AccountState, { status }): AccountState => {
    return {
      ...state,
      refreshTokenFailed: status,
    };
  })
);

export function accountReducer(state: any, action: any) {
  return _accountReducer(state, action);
}
