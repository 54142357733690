import { IndependentProfileInterface } from 'src/app/recruiter/interfaces/independent-profile.interface';
import { IJob } from 'src/app/recruiter/interfaces/job.interface';
import { IProjects } from 'src/app/recruiter/interfaces/projects.interface';
import { RecruiterProfileInterface } from 'src/app/recruiter/interfaces/recruiter-profile.interface';

export const initialState: RecruiterState = {
  jobs: [],
  viewReadOnlyProfileId: '',
  showRecruiterProfile: false,
  recruiterDetails: {
    accountId: '',
    registerType: '',
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    regCompanyName: '',
    tradeName: '',
    companyRegNumber: '',
    establishYear: '',
    registeredCountry: null,
    industryType: '',
    gstin: '',
    primaryOfcAddress: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    ofcPhoneNumber: '',
    emailAddress: '',
    websiteUrl: '',
    aboutCompany: '',
    primaryBusinessFocus: '',
    achievements: '',
    primaryFullName: '',
    primaryJobTitle: '',
    primaryEmail: '',
    primaryPhoneNumber: '',
    primaryLinkedInProfile: '',
    secondaryFullName: '',
    secondaryJobTitle: '',
    secondaryEmail: '',
    secondaryPhoneNumber: '',
    secondaryLinkedInProfile: '',
    productinType: '',
    servicesOffered: '',
    talentNeeds: '',
    collabMethods: '',
    orgLogo: '',
    signedOrgLogoUrl: '',
    previousExperiences: [
      {
        projectTitle: '',
        projectType: '',
        releaseYear: '',
        role: '',
        description: '',
        workLinks: '',
      },
    ],
  },
  viewJobDetailsId: '',
  profileFilter: {},
  projects: [],
  indFilmmakerProfile: {
    fullName: '',
    professionalName: '',
    primaryAddress: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    phoneNumber: '',
    emailAddress: '',
    personalWebsiteUrl: '',
    linkedInLink: '',
    instaLink: '',
    youtubeLink: '',
    otherLink: '',
    degreesInMedia: '',
    institutions: '',
    yearOfGraduation: '',
    yearOfExperience: '',
    primaryRoleFilm: '',
    notableProjects: '',
    awardsRecognition: '',
    projectName: '',
    filmType: '',
    genre: [],
    synopsis: '',
    targetAudience: '',
    estRuntime: '',
    prodStatus: '',
    expReleaseDate: '',
    locationFilm: '',
    budget: '',
    fundSource: '',
    crewMembers: '',
    openRoles: '',
    interviewDates: '',
    projectLink: '',
    previousProject: '',
    previousWorkLink: '',
    hideKeepContact: '',
    allowMessage: '',
    preferredCollaboration: '',
    availableForMeet: '',
    talentMatchNotifications: '',
    platformUpdates: '',
    eventsUpdates: '',
    profileImage: '',
    accountId: '',
    registerType: '',
    signedprofileImageUrl: '',
    emailId: '',
  },
  savedNotes: {},
};

export interface RecruiterState {
  jobs: IJob[];
  recruiterDetails: RecruiterProfileInterface;
  viewReadOnlyProfileId: string;
  viewJobDetailsId: string;
  showRecruiterProfile: boolean;
  profileFilter: any;
  projects: IProjects[];
  indFilmmakerProfile: IndependentProfileInterface;
  savedNotes: any;
}
