export const initialAccountState: AccountState = {
  emailId: '',
  registerType: '',
  accountId: '',
  changePasswordError: '',
  isChangePasswordError: false,
  changePasswordSuccess: '',
  isChangePasswordSuccess: false,
  isAccountVerified: false,
  emailToVerify: '',
  token: '',
  refreshToken: '',
  refreshTokenFailed: false,
  isLoggedIn: false,
  accountVerifiedBySeeneema: false,
};

export interface AccountState {
  emailId: string;
  registerType: string;
  accountId: string;
  changePasswordError: string;
  isChangePasswordError: boolean;
  changePasswordSuccess: string;
  isChangePasswordSuccess: boolean;
  isAccountVerified: boolean;
  emailToVerify: string;
  token: string;
  refreshToken: string;
  refreshTokenFailed: boolean;
  isLoggedIn: boolean;
  accountVerifiedBySeeneema: boolean;
}
