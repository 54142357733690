<div class="wrapper footer-container">
  <div class="row m-5">
    <div class="col-sm-12 col-lg-4">
      <div class="footer-title">seeneemaa</div>
      <div class="wd-100 font-wt-500">Right oppportunities, Great talent!</div>
    </div>
    <div class="col-sm-12 col-lg-4">
      <div class="line-item" [routerLink]="['/behavioral-compliance']">
        Behavioral Compliance
      </div>
      <div class="line-item" [routerLink]="['/terms-of-use']">Terms Of Use</div>
      <div class="line-item" [routerLink]="['/privacy-policy']">
        Privacy Policy
      </div>
      <div class="line-item" [routerLink]="['/aboutus']">About Us</div>
    </div>
    <div class="col-sm-12 col-lg-4">
      <div style="line-height: 30px; cursor: pointer">Follow Us</div>
      <div>
        <a
          href="https://www.instagram.com/seeneemaatfi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          <img
            src="assets/images/instagram.png"
            width="40px"
            height="40px"
            style="margin: 5px 5px 5px 0px"
        /></a>
        <a
          href="https://www.instagram.com/seeneemaatfi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          <img
            src="assets/images/facebook.png"
            width="40px"
            height="40px"
            style="margin: 5px 5px 5px 0px"
        /></a>
        <a
          href="https://www.instagram.com/seeneemaatfi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          <img
            src="assets/images/twitter.png"
            width="40px"
            height="40px"
            style="margin: 5px 5px 5px 0px"
          />
        </a>
        <a
          href="https://www.instagram.com/seeneemaatfi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          <img
            src="assets/images/youtube.png"
            width="40px"
            height="40px"
            style="margin: 5px 5px 5px 0px"
        /></a>
      </div>
    </div>
  </div>
  <div class="row m-3">
    <div class="col-12 text-center">
      Copyright © SEENEEMAA TRIBE & COMMUNE PRIVATE LIMITED 2025. All rights
      reserved.
    </div>
  </div>
</div>
