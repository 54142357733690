import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { ISaveProfileRequest } from 'src/app/shared/interfaces/request/save-profile.interface';
import { MediaUtilityService } from 'src/app/shared/services/media-utility.service';
import { selectLoggedInUser } from '../account/account.selectors';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import {
  getIndFilmMakerProfileDetails,
  getRecruiterDetails,
} from '../recruiter/recruiter.actions';
import {
  deleteProfileImage,
  getAllProfiles,
  getAppliedUserProfile,
  getAppliedUserProfileFailure,
  getAppliedUserProfileSuccess,
  getApprovedEndorsementsForUser,
  getProfileDetails,
  getProfileDetailsError,
  getProfileDetailsSuccess,
  redirectToUserProfilePage,
  setAllProfiles,
  setProfileDetailsAPI,
  setReadOnlyProfile,
  updateApprovedEndorsementsForUser,
  uploadProfileImage,
} from './profile.actions';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private store: Store,
    private mediaService: MediaUtilityService
  ) {}
  setProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProfileDetailsAPI),
      mergeMap((profileNewData) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        let newPayload: ISaveProfileRequest = {
          demographic: profileNewData.payload.demographic,
          skillOptions: profileNewData.payload.skillOptions,
          lookingFor: profileNewData.payload.lookingFor,
          languages: profileNewData.payload.languages,
          physicalAttributes: profileNewData.payload.physicalAttributes,
          specialPhysicalAttributes:
            profileNewData.payload.specialPhysicalAttributes,
          uniqueTalentAbility: profileNewData.payload.uniqueTalentAbility,
          mediaLinks: profileNewData.payload.mediaLinks,
          videoLinks: profileNewData.payload.videoLinks,
          workExperience: profileNewData.payload.workExperience,
          educationDetails: profileNewData.payload.educationDetails,
          profileImage: profileNewData.payload.profileImage,
          portfolio0: profileNewData.payload.portfolio0,
          portfolio1: profileNewData.payload.portfolio1,
          portfolio2: profileNewData.payload.portfolio2,
          portfolio3: profileNewData.payload.portfolio3,
          allowProfileVisibilty: profileNewData.payload.allowProfileVisibilty,
        };
        return this.profileService.setProfileDetails(newPayload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Updated Profile',
                  show: true,
                },
              })
            );
            return getProfileDetails();
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Failed to update profile',
                  show: true,
                },
              })
            );
            return [getProfileDetails()];
          })
        );
      })
    );
  });

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching' } })
        );
        return this.profileService
          .getProfileDetails({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: '',
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'FAILED' &&
                response.code === 'CineRush_00006'
              ) {
                return redirectToUserProfilePage({
                  redirectToUserProfile: true,
                });
              } else {
                this.store.dispatch(
                  redirectToUserProfilePage({ redirectToUserProfile: false })
                );
                return getProfileDetailsSuccess({
                  payload: response.profileDetails,
                });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [getProfileDetailsError({ payload: error })];
            })
          );
      })
    )
  );

  getAllProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllProfiles),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([payload, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.profileService
          .getAllProfiles({
            accountId: loggedInDetails.accountId,
            appliedList: payload.appliedList,
            filter: payload.filter,
            registerType: payload.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                return setAllProfiles({
                  payload: response.profileDetails,
                });
              } else {
                this.store.dispatch(
                  openSnackBar({
                    snackBar: { message: 'No profiles found', show: true },
                  })
                );
                return setAllProfiles({
                  payload: [],
                });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Error while fetching all profiles',
                    show: true,
                  },
                })
              );
              return [
                setAllProfiles({
                  payload: [],
                }),
              ];
            })
          );
      })
    )
  );

  getAppliedProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppliedUserProfile),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([payload, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.profileService
          .getProfileDetails({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: payload.userProfileAccountId,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                this.store.dispatch(
                  getApprovedEndorsementsForUser({
                    targetAccountId: payload.userProfileAccountId,
                  })
                );
                if (payload.isRefresh) {
                  return setReadOnlyProfile({
                    jobId: payload.jobId,
                    profileAccountId: payload.userProfileAccountId,
                    profileData: response.profileDetails,
                  });
                } else {
                  this.store.dispatch(
                    setReadOnlyProfile({
                      jobId: payload.jobId,
                      profileAccountId: payload.userProfileAccountId,
                      profileData: response.profileDetails,
                    })
                  );
                  return getAppliedUserProfileSuccess({ status: true });
                }
              } else {
                return getAppliedUserProfileFailure({ status: true });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [getAppliedUserProfileFailure({ status: true })];
            })
          );
      })
    )
  );

  uploadProfileImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadProfileImage),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([payload, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Uploading...' } })
        );
        payload.data.append('accountId', loggedInDetails.accountId);
        payload.data.append('registerType', loggedInDetails.registerType);
        return this.profileService.uploadProfileImage(payload.data).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Uploaded Successfully.',
                    show: true,
                  },
                })
              );
            }

            return loggedInDetails.registerType === 'USER'
              ? getProfileDetails()
              : loggedInDetails.registerType === 'INDFILMMAKER'
              ? getIndFilmMakerProfileDetails()
              : getRecruiterDetails();
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Upload failed.',
                  show: true,
                },
              })
            );
            return [
              loggedInDetails.registerType === 'USER'
                ? getProfileDetails()
                : loggedInDetails.registerType === 'INDFILMMAKER'
                ? getIndFilmMakerProfileDetails()
                : getRecruiterDetails(),
            ];
          })
        );
      })
    )
  );

  deleteImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProfileImage),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([payload, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Deleting...' } })
        );

        const newPayload = Object.assign({}, payload.payload, {
          accountId: loggedInDetails.accountId,
          registerType: loggedInDetails.registerType,
        });
        return this.mediaService.deleteUploadedImage(newPayload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Deleted Successfully.',
                    show: true,
                  },
                })
              );
            }
            return loggedInDetails.registerType === 'USER'
              ? getProfileDetails()
              : loggedInDetails.registerType === 'INDFILMMAKER'
              ? getIndFilmMakerProfileDetails()
              : getRecruiterDetails();
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Delete failed.',
                  show: true,
                },
              })
            );
            return [
              loggedInDetails.registerType === 'USER'
                ? getProfileDetails()
                : loggedInDetails.registerType === 'INDFILMMAKER'
                ? getIndFilmMakerProfileDetails()
                : getRecruiterDetails(),
            ];
          })
        );
      })
    )
  );

  getApprovedEndorsmentsForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getApprovedEndorsementsForUser),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([payload, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.profileService
          .getApprovedEndorsementsForUser({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: payload.targetAccountId,
          })
          .pipe(
            map((response) => {
              console.log(response);
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                return updateApprovedEndorsementsForUser({
                  endorsements: response.endorsementData,
                });
              } else {
                return updateApprovedEndorsementsForUser({
                  endorsements: [],
                });
              }
            }),
            catchError((error) => {
              toggleSpinner({ spinner: { show: false, message: '' } });
              return [
                updateApprovedEndorsementsForUser({
                  endorsements: [],
                }),
              ];
            })
          );
      })
    )
  );
}
