import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectSnackBar } from 'src/app/store/core/core.selector';

@Component({
  selector: 'snack-bar',
  templateUrl: 'snack-bar.component.html',
})
export class SnackBarComponent implements OnInit, OnDestroy {
  message: string = '';
  durationInSeconds = 5000;
  snackBarRef = inject(MatSnackBarRef);
  subscriptions: Subscription[] = [];
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscriptions[0] = this.store
      .select(selectSnackBar)
      .subscribe((data) => {
        if (data.show) {
          this.message = data.message;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
