import { createReducer, on } from '@ngrx/store';
import {
  editEndorsement,
  setEndorsements,
  setEndorsementsRequestsByUsers,
  setIndustryProfiles,
} from './endorsements.actions';
import { EndorsementsState, initialState } from './endorsements.state';

const _endorsementsReducer = createReducer(
  initialState,
  on(
    setIndustryProfiles,
    (state: EndorsementsState, action): EndorsementsState => {
      return {
        ...state,
        endorsementProfiles: action.industryProfiles,
      };
    }
  ),
  on(setEndorsements, (state: EndorsementsState, action): EndorsementsState => {
    return {
      ...state,
      endorsments: action.endorsements,
    };
  }),
  on(editEndorsement, (state: EndorsementsState, action): EndorsementsState => {
    return {
      ...state,
      editEndorsement: action.endorsement,
    };
  }),
  on(
    setEndorsementsRequestsByUsers,
    (state: EndorsementsState, action): EndorsementsState => {
      return {
        ...state,
        endorsementsRequests: action.endorsements,
      };
    }
  )
);

export function endorsementsReducer(state: any, action: any) {
  return _endorsementsReducer(state, action);
}
