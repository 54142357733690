import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EndorsementsState } from './endorsements.state';

export const featureKey = 'endorsementsState';
export const selectEndorsementsFeature =
  createFeatureSelector<EndorsementsState>(featureKey);

export const selectUserEndorsements = createSelector(
  selectEndorsementsFeature,
  (state: EndorsementsState) => {
    return state.endorsments;
  }
);

export const selectEndorsementToEdit = createSelector(
  selectEndorsementsFeature,
  (state: EndorsementsState) => {
    return state.editEndorsement;
  }
);

export const selectIndustryProfiles = createSelector(
  selectEndorsementsFeature,
  (state: EndorsementsState) => {
    return state.endorsementProfiles;
  }
);

export const selectEndorsementsRequests = createSelector(
  selectEndorsementsFeature,
  (state: EndorsementsState) => {
    return state.endorsementsRequests;
  }
);
