<div class="container mt-5">
  <div class="card shadow p-4">
    <h1 class="text-center">Behavioral Compliance Policy</h1>
    <p class="text-center"><strong>Effective Date:</strong> 24/03/2025</p>
    <p>
      At Seeneemaa, we are committed to maintaining a safe, professional, and
      ethical environment for both talent and recruiters. To ensure fair and
      respectful interactions, all users must adhere to the following
      guidelines.
    </p>

    <h6>1. Code of Conduct</h6>
    <ul>
      <li>✔ Communicate professionally and respectfully.</li>
      <li>
        ✔ Provide accurate and truthful information in profiles, job postings,
        and applications.
      </li>
      <li>
        ✔ Adhere to ethical industry practices in auditions, hiring, and
        collaborations.
      </li>
      <li>
        ✔ Respect confidentiality of information shared during casting and
        recruitment.
      </li>
    </ul>

    <h6>2. Content Guidelines</h6>
    <ul>
      <li>
        🚫 <strong>No Objectionable Content:</strong> Talent must not upload or
        include obscene, offensive, or inappropriate photos, images, videos, or
        text in their profile.
      </li>
      <li>
        👶 <strong>Child Artists:</strong> Profiles of talent under 18 years of
        age are allowed, but parental/guardian consent is mandatory.
      </li>
    </ul>

    <h6>3. Respectful Communication</h6>
    <ul>
      <li>🤝 Talent must maintain respectful conversations with recruiters.</li>
      <li>📢 Recruiters must maintain respectful conversations with talent.</li>
      <li>
        ⚠ Any form of harassment, discrimination, or unprofessional conduct will
        not be tolerated.
      </li>
    </ul>

    <h6>4. Intellectual Property & Data Protection</h6>
    <ul>
      <li>
        📸 Images uploaded by talent must not be reused by recruiters or third
        parties without the talent’s explicit consent.
      </li>
      <li>
        🎭 Recruiters must post only legitimate job opportunities that align
        with industry standards.
      </li>
    </ul>

    <h6>5. Reporting & Compliance</h6>
    <ul>
      <li>
        📩 Seeneemaa accepts grievances from both talent and recruiters and will
        take appropriate action.
      </li>
      <li>
        ⚖ Sensitive cases may be forwarded to local legal authorities as per
        applicable law.
      </li>
      <li>
        🚨 Violation of these policies may result in immediate account
        termination without prior notice.
      </li>
    </ul>

    <h6>Contact Us</h6>
    <p>
      <strong
        >For concerns, please reach out to
        <a href="mailto:support@seeneemaa.com">support&#64;seeneemaa.com</a
        >.</strong
      >
    </p>
  </div>
</div>
