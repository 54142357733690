import { createAction, props } from '@ngrx/store';

export const setLoggedInUser = createAction(
  '[Account] logged in user',
  props<{
    emailId: string;
    accountId: string;
    registerType: string;
    accountVerifiedBySeeneema: boolean;
  }>()
);

export const setUserVerified = createAction(
  '[Account] user login error',
  props<{ status: boolean; emailId: string }>()
);

export const changePassword = createAction(
  '[Account] update password',
  props<{
    payload: {
      oldpswrd: string;
      emailId: string;
      newpswrd: string;
    };
  }>()
);

export const changePasswordSuccess = createAction(
  '[Account] update password success',
  props<{ status: boolean; message: string }>()
);

export const changePasswordError = createAction(
  '[Account] update password error',
  props<{ status: boolean; message: string }>()
);

export const setAuthToken = createAction(
  '[Account] set auth token',
  props<{ token: string }>()
);

export const setRefreshToken = createAction(
  '[Account] set refresh token',
  props<{ token: string }>()
);

export const getTokens = createAction('[Account] get tokens');

export const getTokensFailed = createAction(
  '[Account] get tokens failed',
  props<{ status: boolean }>()
);
