import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { MessagesService } from 'src/app/messages/services/messages.service';
import { selectLoggedInUser } from '../account/account.selectors';
import { toggleSpinner } from '../core/core.actions';
import {
  getChats,
  getChatsError,
  getChatsSuccess,
  getMessages,
  getMessagesError,
  getMessagesSuccess,
  sendMessage,
  sendMessageError,
  sendMessageSuccess,
  setRefreshAllMessages,
} from './messages.actions';

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private messagesService: MessagesService,
    private store: Store
  ) {}

  getAllChats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChats),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([payload, loggedInDetails]) => {
        return this.messagesService.getChats(loggedInDetails.accountId).pipe(
          map((response) => {
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              return getChatsSuccess({
                chats: response.chatResponse,
              });
            } else {
              return getChatsError({
                message: response.succsessMessage,
              });
            }
          }),
          catchError((error) => {
            return [
              getChatsError({
                message: error.message,
              }),
            ];
          })
        );
      })
    )
  );

  getAllMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMessages),
      mergeMap((payload) => {
        return this.messagesService.getMessages(payload.chatId).pipe(
          map((response) => {
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              return getMessagesSuccess({
                messages: response.messageResponse,
              });
            } else {
              return getMessagesError({
                message: response.succsessMessage,
              });
            }
          }),
          catchError((error) => {
            return [
              getMessagesError({
                message: error.message,
              }),
            ];
          })
        );
      })
    )
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessage),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Sending...' } })
        );
        return this.messagesService.sendMessage(payload.message).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              this.store.dispatch(setRefreshAllMessages({ refreshAll: true }));
              return sendMessageSuccess({
                isSendMessageSuccess: true,
              });
            } else {
              return sendMessageError({
                isSendMessageFailure: true,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            return [
              sendMessageError({
                isSendMessageFailure: true,
              }),
            ];
          })
        );
      })
    )
  );
}
