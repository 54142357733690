import { createReducer, on } from '@ngrx/store';
import {
  openSnackBar,
  toggleBottomSheet,
  toggleSpinner,
  updateUrlPath,
} from './core.actions';
import { CoreState, initialState } from './core.state';

const _coreReducer = createReducer(
  initialState,
  on(toggleSpinner, (state: CoreState, { spinner }): CoreState => {
    return {
      ...state,
      spinner: spinner,
    };
  }),
  on(openSnackBar, (state: CoreState, { snackBar }): CoreState => {
    return {
      ...state,
      snackBar: snackBar,
    };
  }),
  on(updateUrlPath, (state: CoreState, { urlPath }): CoreState => {
    return {
      ...state,
      currentUrl: urlPath,
    };
  }),
  on(toggleBottomSheet, (state: CoreState, { show, data }): CoreState => {
    return {
      ...state,
      bottomSheet: { show, data },
    };
  })
);

export function coreReducer(state: any, action: any) {
  return _coreReducer(state, action);
}
