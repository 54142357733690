<div class="container mt-5">
  <div class="card shadow p-4 text-center">
    <h1 class="text-primary">Seeneemaa: Where Talent Meets Opportunity</h1>
    <p class="lead">
      Seeneemaa is a dedicated platform for the Telugu film industry, designed
      to bridge the gap between talent, filmmakers, and production houses.
    </p>

    <p>
      Whether you're an actor, writer, technician, or recruiter, Seeneemaa
      streamlines casting, auditions, script collaborations, and networking,
      making talent discovery and hiring seamless.
    </p>

    <h2 class="mt-4">Our Mission</h2>
    <p>
      To empower aspiring talent and simplify hiring for the entertainment
      industry by creating a trusted, transparent, and efficient ecosystem.
    </p>

    <h2 class="mt-4">Why Seeneemaa?</h2>
    <ul
      class="list-group list-group-flush text-start mx-auto"
      style="max-width: 600px"
    >
      <li class="list-group-item">
        ✅ <strong>Industry-Centric:</strong> Designed specifically for Telugu
        cinema professionals.
      </li>
      <li class="list-group-item">
        ✅ <strong>Smart Talent Discovery:</strong> AI-driven recommendations
        for casting and hiring.
      </li>
      <li class="list-group-item">
        ✅ <strong>Seamless Collaboration:</strong> One-stop platform for
        auditions, applications, and project management.
      </li>
    </ul>

    <h3 class="mt-4">
      Join Seeneemaa and take your cinema journey to the next level!
    </h3>

    <p class="mt-3">
      <strong
        >📧 Contact Us:
        <a href="mailto:support@seeneemaa.com">support@seeneemaa.com</a></strong
      >
    </p>
  </div>
</div>
