<div class="container mt-5">
  <div class="card shadow p-4">
    <h1 class="text-center">Terms of Use</h1>
    <p class="text-center"><strong>Effective Date:</strong> 24/03/2025</p>
    <div>
      <p>
        These Terms of Use govern your access to and use of Seeneemaa. By using
        our platform, you agree to comply with these terms.
      </p>
  
      <h6>1. User Eligibility</h6>
      <ul>
        <li>Users must be 18 years or older or have parental consent.</li>
        <li>
          Seeneemaa reserves the right to suspend accounts that violate these
          terms.
        </li>
      </ul>
  
      <h6>2. Account Responsibility</h6>
      <ul>
        <li>Users are responsible for maintaining account security.</li>
        <li>Sharing login credentials is strictly prohibited.</li>
      </ul>
  
      <h6>3. Platform Usage</h6>
      <ul>
        <li>
          <strong>Talent Profiles:</strong> Must provide accurate information.
        </li>
        <li>
          <strong>Recruiters & Production Houses:</strong> Must post legitimate
          job opportunities.
        </li>
        <li>
          Any fraudulent, misleading, or inappropriate content will result in
          account termination.
        </li>
      </ul>
  
      <h6>4. Payments & Subscriptions</h6>
      <ul>
        <li>
          Paid services are non-refundable unless specified in the refund policy.
        </li>
        <li>
          Seeneemaa is not liable for any disputes between talent and recruiters.
        </li>
      </ul>
  
      <h6>5. Liability Disclaimer</h6>
      <ul>
        <li>Seeneemaa is a facilitator and does not guarantee job placements.</li>
        <li>We are not responsible for any disputes between users.</li>
      </ul>
      <h6>6. Amendments to Terms</h6>
      <ul>
        <li>
          Seeneemaa may update these terms periodically. Users will be notified of
          changes.
        </li>
      </ul>
  
      <h6>7. Contact Us</h6>
      <p>
        <strong
          >For queries, contact
          <a href="mailto:support&#64;seeneemaa.com">support&#64;seeneemaa.com</a>.</strong
        >
      </p>
    </div>
  </div>
</div>
