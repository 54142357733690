import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IProjects } from 'src/app/recruiter/interfaces/projects.interface';
import { toggleBottomSheet } from 'src/app/store/core/core.actions';
import {
  getProjects,
  updateShortlistProfiles,
} from 'src/app/store/recruiter/recruiter.actions';
import { selectProjects } from 'src/app/store/recruiter/recruiter.selector';
import { CreateShortlistPopupComponent } from '../create-shortlist-popup/create-shortlist-popup.component';
@Component({
  selector: 'shortlist-bottom-popup',
  templateUrl: 'shortlist-bottom-popup.component.html',
})
export class ShortlistBottomPopupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  private projectsSubject: BehaviorSubject<IProjects[]> = new BehaviorSubject<
    IProjects[]
  >([]);
  projects$: Observable<IProjects[]> = this.projectsSubject.asObservable();
  selectedProject: IProjects | null = null;
  selectedShortlistNames: string[] = [];
  storedShortlistNamesForUser: string[] = [];
  constructor(
    private store: Store,
    private dialog: MatDialog,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      accountId: string;
      jobId: string;
    }
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getProjects());
    this.subscriptions[0] = this.store
      .select(selectProjects)
      .subscribe((projects) => this.projectsSubject.next(projects));
  }

  close(): void {
    this.store.dispatch(
      toggleBottomSheet({
        show: false,
        data: {
          accountId: '',
          jobId: '',
        },
      })
    );
  }

  save(): void {
    console.log(this.selectedShortlistNames, this.storedShortlistNamesForUser);
    const shortlistMap = new Map<string, boolean>();

    // Create a Set for quick lookup
    const selectedSet = new Set(this.selectedShortlistNames);
    const storedSet = new Set(this.storedShortlistNamesForUser);

    // Process stored names
    this.storedShortlistNamesForUser.forEach((name) => {
      if (selectedSet.has(name)) {
        shortlistMap.set(name, true);
      } else {
        shortlistMap.set(name, false);
      }
    });

    // Process newly selected names that were not in stored
    this.selectedShortlistNames.forEach((name) => {
      if (!storedSet.has(name)) {
        shortlistMap.set(name, true);
      }
    });

    console.log(shortlistMap);
    this.store.dispatch(
      updateShortlistProfiles({
        projectId: this.selectedProject?.projectId!,
        shortListName: shortlistMap,
        targetedAccountId: this.data.accountId,
      })
    );
    this.close();
  }

  createNewShortlist(): void {
    console.log('Create New Shortlist');
    this.dialog.open(CreateShortlistPopupComponent, {
      data: {
        title: 'Create Shortlist',
        data: {
          profileData: '',
          jobId: '',
        },
        positiveBtnLabel: 'Create',
        negativeBtnLabel: 'Cancel',
      },
    });
    this.close();
  }

  selectProject(selectedProject: IProjects) {
    console.log('selectedProject', selectedProject);
    this.selectedProject = selectedProject;
    this.selectedProject.shortlists.forEach((item) => {
      if (item.shortlist.includes(this.data.accountId)) {
        this.selectedShortlistNames.push(item.name);
        this.storedShortlistNamesForUser.push(item.name);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
