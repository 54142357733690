import { createReducer, on } from '@ngrx/store';
import {
  getAppliedUserProfileFailure,
  getAppliedUserProfileSuccess,
  getProfileDetailsError,
  getProfileDetailsSuccess,
  redirectToUserProfilePage,
  resetReadOnlyProfile,
  setAllProfiles,
  setReadOnlyProfile,
  updateApprovedEndorsementsForUser,
  updateLoginPersonalData,
  updateProfileState,
} from './profile.actions';
import { ProfileState, initialState } from './profile.state';

const _profileReducer = createReducer(
  initialState,
  on(
    updateProfileState,
    (state: ProfileState, { profileDetails }): ProfileState => {
      return {
        ...state,
        profile: { ...profileDetails },
      };
    }
  ),
  on(
    updateLoginPersonalData,
    (
      state: ProfileState,
      { emailId, registerType, firstName, lastName, phoneNumber, accountId }
    ): ProfileState => {
      return {
        ...state,
        profile: {
          ...state.profile,
          demographic: {
            ...state.profile.demographic,
            emailId: emailId,
            accountId: accountId,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            registerType: registerType,
          },
        },
        registerType: registerType,
      };
    }
  ),
  on(
    getProfileDetailsSuccess,
    (state: ProfileState, { payload }): ProfileState => {
      return {
        ...state,
        profile: { ...payload },
      };
    }
  ),
  on(
    getProfileDetailsError,
    (state: ProfileState, { payload }): ProfileState => {
      return { ...state, getProfileDetailsError: payload };
    }
  ),
  on(setAllProfiles, (state: ProfileState, { payload }): ProfileState => {
    return {
      ...state,
      allProfiles: payload,
    };
  }),
  on(
    redirectToUserProfilePage,
    (state: ProfileState, { redirectToUserProfile }): ProfileState => {
      return {
        ...state,
        redirectToUserProfile: redirectToUserProfile,
      };
    }
  ),
  on(
    setReadOnlyProfile,
    (
      state: ProfileState,
      { profileAccountId, jobId, profileData }
    ): ProfileState => {
      return {
        ...state,
        appliedProfile: {
          profileAccountId: profileAccountId,
          jobId: jobId,
          profileData: profileData,
        },
      };
    }
  ),
  on(
    updateApprovedEndorsementsForUser,
    (state: ProfileState, { endorsements }): ProfileState => {
      return {
        ...state,
        appliedProfile: {
          jobId: state.appliedProfile.jobId,
          profileAccountId: state.appliedProfile.profileAccountId,
          profileData: {
            ...state.appliedProfile.profileData,
            endorsements: endorsements,
          },
        },
      };
    }
  ),
  on(resetReadOnlyProfile, (state: ProfileState): ProfileState => {
    return {
      ...state,
      appliedProfile: {
        profileAccountId: initialState.appliedProfile.profileAccountId,
        jobId: initialState.appliedProfile.jobId,
        profileData: initialState.appliedProfile.profileData,
      },
    };
  }),
  on(
    getAppliedUserProfileSuccess,
    (state: ProfileState, { status }): ProfileState => {
      return {
        ...state,
        isAppliedProfileSuccess: status,
      };
    }
  ),
  on(
    getAppliedUserProfileFailure,
    (state: ProfileState, { status }): ProfileState => {
      return {
        ...state,
        isAppliedProfileFailure: status,
      };
    }
  )
);

export function profileReducer(state: any, action: any) {
  return _profileReducer(state, action);
}
