import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs';
import { EndorsementsService } from 'src/app/endorsements/services/endorsements.service';
import { selectLoggedInUser } from '../account/account.selectors';
import { openSnackBar } from '../core/core.actions';
import {
  approveOrDeclineEndorsement,
  getEndorsements,
  getEndorsementsRequestsByUsers,
  getIndustryProfiles,
  removeEndorsementRequest,
  setEndorsements,
  setEndorsementsRequestsByUsers,
  setIndustryProfiles,
  submitEndorsementRequest,
  updateEndorsementRequest,
} from './endorsements.actions';
import { selectUserEndorsements } from './endorsements.selector';

@Injectable()
export class EndorsementsEffects {
  constructor(
    private endorsementsService: EndorsementsService,
    private actions$: Actions,
    private store: Store
  ) {}

  getIndustryProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIndustryProfiles),
      switchMap(() =>
        this.endorsementsService.getIndustryProfilesForEndorsement().pipe(
          map((response) => {
            if (response?.code === 'CineRush_00001') {
              return setIndustryProfiles({
                industryProfiles: response.endorsements,
              });
            }
            return setIndustryProfiles({ industryProfiles: [] });
          }),
          catchError((error) => {
            console.error(error);
            return [setIndustryProfiles({ industryProfiles: [] })];
          })
        )
      )
    )
  );

  getEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEndorsements),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      switchMap(([action, loggedInDetails]) =>
        this.endorsementsService
          .getEndorsements({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
          })
          .pipe(
            map((response) => {
              if (response?.code === 'CineRush_00001') {
                return setEndorsements({
                  endorsements: response.endorsementData,
                });
              }
              return setEndorsements({ endorsements: [] });
            }),
            catchError((error) => {
              console.error(error);
              return [setEndorsements({ endorsements: [] })];
            })
          )
      )
    )
  );

  getEndorsementsRequestsByUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEndorsementsRequestsByUsers),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      switchMap(([action, loggedInDetails]) =>
        this.endorsementsService
          .getEndorsements({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
          })
          .pipe(
            map((response) => {
              if (response?.code === 'CineRush_00001') {
                return setEndorsementsRequestsByUsers({
                  endorsements: response.endorsementData,
                });
              }
              return setEndorsementsRequestsByUsers({ endorsements: [] });
            }),
            catchError((error) => {
              console.error(error);
              return [setEndorsementsRequestsByUsers({ endorsements: [] })];
            })
          )
      )
    )
  );

  submitEndorsementRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitEndorsementRequest),
      withLatestFrom(
        this.store.pipe(select(selectLoggedInUser)),
        this.store.pipe(select(selectUserEndorsements))
      ),
      switchMap(([action, loggedInDetails, endorsements]) => {
        const isEndorsementAlreadyRequested = endorsements.some(
          (endorsement) =>
            endorsement.targetedAccountId ===
            action.endorsemntsWithNotes[0].targetedAccountId
        );
        if (isEndorsementAlreadyRequested) {
          return [
            openSnackBar({
              snackBar: {
                message: 'Endorsement already requested',
                show: true,
              },
            }),
          ];
        }
        return this.endorsementsService
          .submitEndorsementRequest({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            endorsemntsWithNotes: [
              {
                targetedAccountId:
                  action.endorsemntsWithNotes[0].targetedAccountId,
                notes: action.endorsemntsWithNotes[0].notes,
              },
            ],
          })
          .pipe(
            map((endorsements) => getEndorsements()),
            catchError((error) => {
              console.error(error);
              return [getEndorsements()];
            })
          );
      })
    )
  );

  removeEndorsementRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeEndorsementRequest),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      switchMap(([action, loggedInDetails]) =>
        this.endorsementsService
          .removeEndorsementRequest({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: action.targetedAccountId,
          })
          .pipe(
            map((endorsements) => getEndorsements()),
            catchError((error) => {
              console.error(error);
              return [getEndorsements()];
            })
          )
      )
    )
  );

  updateEndorsementRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEndorsementRequest),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      switchMap(([action, loggedInDetails]) =>
        this.endorsementsService
          .updateEndorsementRequest({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: action.targetedAccountId,
            notes: action.notes,
          })
          .pipe(
            map((endorsements) => getEndorsements()),
            catchError((error) => {
              console.error(error);
              return [getEndorsements()];
            })
          )
      )
    )
  );

  approveOrDeclineEndorsement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveOrDeclineEndorsement),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      switchMap(([action, loggedInDetails]) =>
        this.endorsementsService
          .approveOrDeclineEndorsement({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: action.targetedAccountId,
            isEndorsementApproved: action.isEndorsementApproved,
            isEndorsementDenied: action.isEndorsementDenied,
          })
          .pipe(
            map((endorsements) => getEndorsementsRequestsByUsers()),
            catchError((error) => {
              console.error(error);
              return [getEndorsementsRequestsByUsers()];
            })
          )
      )
    )
  );
}
