import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  IProjects,
  IShortlist,
} from 'src/app/recruiter/interfaces/projects.interface';
import {
  createProject,
  updateShortlistName,
} from 'src/app/store/recruiter/recruiter.actions';
@Component({
  selector: 'create-shortlist-popup',
  templateUrl: 'create-shortlist-popup.component.html',
})
export class CreateShortlistPopupComponent implements OnInit, OnDestroy {
  firstFormGroup = this._formBuilder.group({
    title: ['', Validators.required],
    description: [''],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      data: IProjects;
      positiveBtnLabel: string;
      negativeBtnLabel: string;
      isEditProject: boolean;
      isEditShortlist: boolean;
      isAddShortlist: boolean;
      editShortlist: IShortlist;
    },
    private dialogRef: MatDialogRef<CreateShortlistPopupComponent>,
    private _formBuilder: FormBuilder,
    private store: Store
  ) {
    // console.log(data);
  }

  ngOnInit(): void {
    if (this.data.data) {
      this.firstFormGroup.patchValue({
        title: this.data.data.title,
        description: this.data.data.description,
      });
    }
    if (this.data.editShortlist) {
      this.secondFormGroup.patchValue({
        secondCtrl: this.data.editShortlist.name,
      });
    }
  }

  ngOnDestroy(): void {}

  save(): void {
    this.dialogRef.close();
    if (this.data.isEditProject) {
      this.store.dispatch(
        createProject({
          title: this.firstFormGroup.value.title!,
          description: this.firstFormGroup.value.description!,
          shortlists: this.data.data.shortlists,
          projectId: this.data.data.projectId,
          isActive: true,
        })
      );
    } else if (this.data.isEditShortlist) {
      this.store.dispatch(
        updateShortlistName({
          projectId: this.data.data.projectId!,
          deleteShortlist: false,
          isShortListNameUpdated: true,
          oldshortlistName: this.data.editShortlist.name,
          shortlistName: this.secondFormGroup.value.secondCtrl!,
        })
      );
    } else if (this.data.isAddShortlist) {
      this.store.dispatch(
        updateShortlistName({
          projectId: this.data.data.projectId!,
          deleteShortlist: false,
          isShortListNameUpdated: false,
          shortlistName: this.secondFormGroup.value.secondCtrl!,
        })
      );
    } else {
      this.store.dispatch(
        createProject({
          title: this.firstFormGroup.value.title!,
          description: this.firstFormGroup.value.description!,
          shortlists: [
            {
              name: this.secondFormGroup.value.secondCtrl!,
              shortlist: [],
              criteria: [],
            },
          ],
          isActive: true,
        })
      );
    }
  }
}
