import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AddNotesDialog } from './components/add-notes-popup/add-notes-dialog.component';
import { AdvancedFilterProfilesDialog } from './components/advanced-filter-profiles/advanced-filter-profiles.component';
import { AppliedProfileCardsComponent } from './components/applied-profile-cards/applied-profile-cards.component';
import { BehavioralComplianceComponent } from './components/behavioral-compliance/behavioral-compliance.component';
import { ConfirmationPopup } from './components/confirmation-popup/confirmation-popup.component';
import { CreateShortlistPopupComponent } from './components/create-shortlist-popup/create-shortlist-popup.component';
import { CustomTabsComponent } from './components/custom-tabs/custom-tabs.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FilterCreatedJobsComponent } from './components/filter-created-jobs/filter-created-jobs.component';
import { FilterJobsComponent } from './components/filter-jobs/filter-jobs.component';
import { FilterProfilesJobsApplied } from './components/filter-profiles-jobs-applied/filter-profiles-jobs-applied.component';
import { FilterProfilesComponent } from './components/filter-profiles/filter-profiles.component';
import { FooterComponent } from './components/footer/footer.component';
import { ImageViewPopupComponent } from './components/image-view-popup/image-view-popup.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PhotoUploadGuidelines } from './components/photo-upload-guidelines/photo-upload-guidelines.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShortlistBottomPopupComponent } from './components/shortlist-bottom-popup/shortlist-bottom-popup.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { TermsOfUserComponent } from './components/terms-of-use/terms-of-use.component';
import { PhoneDialerPipe } from './utils/phone-dialer.pipe';
import { SafePipe } from './utils/safe.pipe';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    PhoneDialerPipe,
    SafePipe,
    LandingPageComponent,
    FilterJobsComponent,
    FilterProfilesComponent,
    FilterCreatedJobsComponent,
    FeedbackComponent,
    SettingsComponent,
    FooterComponent,
    SnackBarComponent,
    ConfirmationPopup,
    CustomTabsComponent,
    NavBarComponent,
    ImageViewPopupComponent,
    AppliedProfileCardsComponent,
    FilterProfilesJobsApplied,
    AdvancedFilterProfilesDialog,
    AddNotesDialog,
    ShortlistBottomPopupComponent,
    CreateShortlistPopupComponent,
    PhotoUploadGuidelines,
    AboutUsComponent,
    TermsOfUserComponent,
    PrivacyPolicyComponent,
    BehavioralComplianceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    MatSliderModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatBottomSheetModule,
    MatListModule,
    MatSelectModule,
    MatStepperModule,
  ],
  exports: [
    PageNotFoundComponent,
    PhoneDialerPipe,
    SafePipe,
    LandingPageComponent,
    FilterJobsComponent,
    FilterProfilesComponent,
    FilterCreatedJobsComponent,
    FeedbackComponent,
    SettingsComponent,
    FooterComponent,
    SnackBarComponent,
    CustomTabsComponent,
    NavBarComponent,
    AppliedProfileCardsComponent,
    FilterProfilesJobsApplied,
    PhotoUploadGuidelines,
    AboutUsComponent,
    TermsOfUserComponent,
    PrivacyPolicyComponent,
    BehavioralComplianceComponent,
  ],
  providers: [PhoneDialerPipe, SafePipe],
})
export class SharedModule {}
