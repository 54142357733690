import { createReducer, on } from '@ngrx/store';
import { onLikeShowCaseSuccess, setShowCases } from './showcase.actions';
import { initialState, ShowCaseState } from './showcase.state';

const _showcaseReducer = createReducer(
  initialState,
  on(setShowCases, (state: ShowCaseState, action): ShowCaseState => {
    return {
      ...state,
      showCases: action.showCases,
    };
  }),
  on(onLikeShowCaseSuccess, (state: ShowCaseState, action): ShowCaseState => {
    let updatedShowCases = [...state.showCases];
    updatedShowCases = updatedShowCases.map((item) => {
      if (item.showCaseId === action.showCaseId) {
        let updatedItem = { ...item };
        updatedItem.isLiked = action.currentLikeStatus;
        return updatedItem;
      } else {
        return item;
      }
    });
    return {
      ...state,
      showCases: updatedShowCases,
    };
  })
);

export function showcaseReducer(state: any, action: any) {
  return _showcaseReducer(state, action);
}
