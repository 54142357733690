<div class="wrapper shortlist-bottom-sheet-wrapper">
  <div
    class="header"
    style="
      border-bottom: 1px solid #bfbfbf;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    "
  >
    <div><div style="font-weight: 500">Add profile to</div></div>
    <div>
      <div style="color: blue" (click)="createNewShortlist()">
        + Create new list
      </div>
    </div>
  </div>

  <div class="content">
    <div style="display: flex; flex-direction: row">
      <div class="col-lg-6 col-sm-12">
        <div>
          <mat-selection-list #shoes [multiple]="false">
            <mat-list-option
              *ngFor="let item of projects$ | async"
              [value]="item.projectId"
              (click)="selectProject(item)"
            >
              <div>{{ item.title }} {{ item.description }}</div>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12" *ngIf="selectedProject">
        <mat-selection-list
          #shoes
          [multiple]="true"
          [(ngModel)]="selectedShortlistNames"
        >
          <mat-list-option
            *ngFor="let item of selectedProject.shortlists"
            [value]="item.name"
          >
            {{ item.name }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
  <div class="footer">
    <div>
      <button class="btn btn-outline-danger" (click)="close()">Close</button>
    </div>
    <div></div>
    <div>
      <button class="btn btn-primary save-btn" (click)="save()">Save</button>
    </div>
  </div>
</div>
