import { Injectable } from '@angular/core';
import { IConfig } from '../interfaces/config.interface';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  appConfigData: IConfig = {
    appName: '',
    genderOptions: [],
    skinToneOptions: [],
    eyeColorOptions: [],
    hairColorOptions: [],
    yesNoOptions: [],
    skillOptions: [],
    lookingForOptions: [],
    attributesOptions: {
      weightOptions: [],
      heightOptions: [],
      chestOptions: [],
      waistOptions: [],
      bodyTypeOptions: [],
      hairTypeOptions: [],
    },
    registerTypeOptions: [],
    languages: [],
    genres: [],
    servicesOfferedOptions: [],
    preferredCollabMethodsOptions: [],
    uniqueTalentAbilityOptions: {
      physicalSkills: [],
      combatWeaponSkills: [],
      danceMovement: [],
      performanceArts: [],
      adventureSurvivalSkills: [],
      culturalLinguisticSkills: [],
      uniquePhysicalTalents: [],
      stuntHighRiskSkills: [],
      artisticTechnicalSkills: [],
      miscellaneousSkills: [],
    },
    specialPhysicalAttributesOptions: {
      distinctiveFacialFeatures: [],
      eyesAndExpressions: [],
      hairFeatures: [],
      skinAndComplexion: [],
      bodyTypeAndStructure: [],
      handsAndFeet: [],
      uniqueMovementOrBodyControl: [],
      otherMemorableTraits: [],
    },
    productionPhases: [],
    collaborationModes: [],
    movieGenres: [],
  };

  constructor() {}
}
