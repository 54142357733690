<div class="container mt-5">
  <div class="card shadow p-4">
    <h1 class="text-center">Privacy Policy</h1>
    <p class="text-center"><strong>Effective Date:</strong> 24/03/2025</p>
    <p>
      Welcome to Seeneemaa! Your privacy is important to us, and we are
      committed to protecting the personal information you share with us.
    </p>

    <h6>1. Information We Collect</h6>
    <ul>
      <li>
        <strong>Personal Information:</strong> Name, email, phone number,
        location, and profile details.
      </li>
      <li>
        <strong>Usage Data:</strong> IP address, device details, browsing
        behavior.
      </li>
      <li>
        <strong>Payment Information:</strong> Processed securely through
        third-party payment gateways.
      </li>
    </ul>

    <h6>2. How We Use Your Information</h6>
    <ul>
      <li>Provide and improve our services.</li>
      <li>Connect talent with recruiters and enable job applications.</li>
      <li>
        Personalize user experiences and recommend relevant opportunities.
      </li>
      <li>Ensure compliance with security and legal requirements.</li>
    </ul>

    <h6>3. Information Sharing</h6>
    <ul>
      <li>We do not sell your personal information.</li>
      <li>
        Information may be shared with recruiters, production houses, or
        third-party service providers only for platform functionality.
      </li>
    </ul>

    <h6>4. Data Security</h6>
    <ul>
      <li>We implement encryption and secure servers to protect your data.</li>
      <li>However, users should exercise caution while sharing sensitive details.</li>
    </ul>

    <h6>5. Your Rights</h6>
    <ul>
      <li>You can update or delete your profile anytime.</li>
      <li>You can opt out of marketing communications.</li>
    </ul>

    <h6>6. Contact Us</h6>
    <p>
      <strong
        >For privacy-related concerns, reach out to
        <a href="mailto:support@seeneemaa.com">support&#64;seeneemaa.com</a
        >.</strong
      >
    </p>
  </div>
</div>
